<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as DOCUMENT_TYPE_TYPES } from './store'
import { ROUTES as DOCUMENT_TYPE_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import DocumentTypeTable from './DocumentTypeTable'
import DocumentTypeCommonFilters from './DocumentTypeCommonFilters'
import DocumentTypeEdit from './DocumentTypeEdit.vue'

export default {
  name: 'DocumentTypeList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': DocumentTypeTable,
    'common-filters': DocumentTypeCommonFilters,
    'component-edit': DocumentTypeEdit,
    'component-detail': DocumentTypeEdit
  },
  data () {
    return {
      DOCUMENT_TYPE_ROUTES,
      title: this.$t('Document type'),
      rolePerm: ['document_documenttype_list'],
      actionEnablePermission: ['document_documenttype_enable'],
      actionDisablePermission: ['document_documenttype_disable'],
      searchFields: [
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...DOCUMENT_TYPE_TYPES.GENERIC.document.documenttype.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.DOCUMENT_TYPE_ROUTES.DOCUMENT_TYPE_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('document_documenttype_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: DOCUMENT_TYPE_TYPES.GENERIC.document.documenttype.LIST.MUTATIONS.setSelectedList,
      setSelectAll: DOCUMENT_TYPE_TYPES.GENERIC.document.documenttype.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...DOCUMENT_TYPE_TYPES.GENERIC.document.documenttype.LIST.ACTIONS
    })
  }
}
</script>
