<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="errors['name']"
            :label-form="'Name' | translate"
            class-form="col-6 mb-2"
            type="text"
            :value="form.name"
            :disabled="!formEditable"
            @change="$set(form, 'name', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.description"
            :external-errors="errors['description']"
            :label-form="'Description' | translate"
            class-form="col-6 mb-2"
            type="text"
            :value="form.description"
            :disabled="!formEditable"
            @change="$set(form, 'description', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-checkbox
            :validator="$v.form.is_training"
            :external-errors="errors['is_training']"
            :label-form="'Training category' | translate"
            class-form="col-3 mb-2"
            label-cols-lg="4"
            label-cols-sm="4"
            :value="form.is_training"
            :disabled="!formEditable"
            @change="$set(form, 'is_training', $event)"
          />
          <form-thux-horizontal-checkbox
            :validator="$v.form.is_organization_document"
            :external-errors="errors['is_organization_document']"
            :label-form="'Organization documents' | translate"
            class-form="col-3 mb-2"
            label-cols-lg="4"
            label-cols-sm="4"
            :value="form.is_organization_document"
            :disabled="!formEditable"
            @change="$set(form, 'is_organization_document', $event)"
          />
          <form-thux-horizontal-checkbox
            :validator="$v.form.is_nomination_document"
            :external-errors="errors['is_nomination_document']"
            :label-form="'Nominations' | translate"
            class-form="col-3 mb-2"
            label-cols-lg="4"
            label-cols-sm="4"
            :value="form.is_nomination_document"
            :disabled="!formEditable"
            @change="$set(form, 'is_nomination_document', $event)"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <div class="mt-3 f-w-600 d-flex justify-content-end">
          <b-button
            class="mr-3"
            variant="default"
            @click.prevent="formEditable ? cancel() : closeForm()">
            {{ formEditable ? 'Cancel' : 'Close' | translate }}
          </b-button>
          <b-button
            variant="primary"
            v-has-perms="editRolePerm"
            @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
            :disabled="$v.form.$invalid">
            {{ formEditable ? 'Save' : 'Edit' | translate }}
          </b-button>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as DOCUMENT_TYPE_TYPES } from './store'

import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'

export default {
  name: 'DocumentTypeEdit',
  mixins: [ThuxDetailPanelMixin],
  data () {
    return {
      pageName: 'Document type',
      rolePerm: 'document_documenttype_retrieve',
      editRolePerm: 'document_documenttype_update'
    }
  },
  computed: {
    ...mapGetters({
      ...DOCUMENT_TYPE_TYPES.GENERIC.document.documenttype.DETAIL.GETTERS
    })
  },
  components: { },
  methods: {
    ...mapActions({
      ...DOCUMENT_TYPE_TYPES.GENERIC.document.documenttype.DETAIL.ACTIONS
    })
  },
  validations: {
    form: {
      name: { required },
      description: { },
      is_training: { },
      is_organization_document: { },
      is_nomination_document: { }
    }
  }
}
</script>
